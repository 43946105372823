<template>
  <div
    class="header"
    :style="{
      background: isHomeAndTop ? 'rgba(255,255,255,0)' : '#fff',
      boxShadow: isHomeAndTop
        ? 'rgba(0, 0, 0, 0) 0px 0px 0px 0px'
        : 'rgba(0, 0, 0, 0.16) 0px 1px 5px 0px',
      paddingRight: isScrollBar ? '17px' : '0px',
    }"
  >
    <div class="header_warpper" v-if="navBarList.navigationMenuList">
      <div class="logo">
        <img
          :src="
            isHomeAndTop
              ? QNPath + navBarList.siteLogoOne
              : QNPath + navBarList.siteLogoTwo
          "
          width="180"
          alt=""
          style="cursor:pointer"
          @click="goHome"
        />
      </div>
      <div class="navs">
        <ul>
          <li
            v-for="(item, index) in navBarList.navigationMenuList"
            :key="item.id"
            :class="
              `${
                isHomeAndTop
                  ? item.moduleCode == activeRoute
                    ? 'activeNavisHome'
                    : ''
                  : item.moduleCode == activeRoute
                  ? 'activeNavNoHome'
                  : ''
              } ${isHomeAndTop && activePath == '/' ? 'isHome' : 'noHome'}`
            "
            @click="navCheck(item, index)"
            v-on:mouseover="showToggle(index,item.moduleNameUser)"
            v-on:mouseout="handleHide"
            :style="{ color: isHomeAndTop ? '#fff' : '#606978' }"
          >
            {{ item.moduleNameUser }}
            <block v-show="item.portalTwoModuleConfigVOList && item.portalTwoModuleConfigVOList.length != 0 && index === isShow">
              <!-- index === isShow -->
              <div class="max-box hoverShow">
                <div v-for="(twoModule,index) in item.portalTwoModuleConfigVOList" :key="index"
                @click="navToTwoModule(twoModule, index)" v-on:mouseover="showGeniusQcode(twoModule.moduleNameUser)" 
                v-on:mouseout="hideGeniusQcode(twoModule.moduleNameUser)">
                  {{twoModule.moduleNameUser}}
                </div>
                <div v-if="GeniusQcode" class="qcodeImg">
                  <img src="../assets/imgs/qcode.jpg" alt="" style="width:150px">
                </div>
              </div>
            </block>
          </li>
        </ul>
      </div>
      <div class="search_box" v-if="navBarList.showSearch">
        <p
          class="search"
          :style="{
            width: isSearch ? '242px !important' : '',
            paddingLeft: isSearch ? '14px' : '0',
            background: !isHomeAndTop ? '#F8F9FA' : '',
          }"
        >
          <a-input
            placeholder="搜索课程、勋章、赛事、资讯"
            v-model="keyWord"
            @keydown="enter"
            ref="inp_search"
          />
          <span class="search_btn" @click="clickSearch"
            ><img src="../assets/imgs/seach.png" alt=""
          /></span>
        </p>
      </div>
      <div class="users">
        <!-- 未登录 -->
        <div class="no_login" v-if="!proToken">
          <a-button
            class="btn login_btn"
            :style="{
              color: isHomeAndTop ? '#fff' : '',
              borderColor: isHomeAndTop ? '#fff' : '',
              background: isHomeAndTop ? 'none' : '',
            }"
            @click="goToLogin"
            >登录</a-button
          >
          <!-- <a-button
            class="btn reg_btn"
            :style="{
              color: isHomeAndTop ? '#5d5aed' : '#fff',
              borderColor: isHomeAndTop ? '#fff' : '',
              background: isHomeAndTop
                ? '#fff'
                : 'linear-gradient(135deg, rgb(132, 60, 246), rgb(56, 184, 242))',
            }"
            @click="toRegiste"
            v-if="navBarList.isRegistrationOpen == 1"
            >注册</a-button
          > -->
        </div>
        <!-- 已登录 -->
        <div class="yes_login" v-else-if="proToken">
          <div class="my_room_box" v-if="navBarList.showPersonalSpace">
            <a-button
              class="btn my_room"
              :style="{
                color: isHomeAndTop ? '#fff' : '',
                borderColor: isHomeAndTop ? '#fff' : '',
                background: isHomeAndTop ? 'none' : '',
              }"
              @click="goPersonalSpace"
            >
              个人空间
              <div class="dot" v-if="submitCount">{{submitCount}}</div>
            </a-button>
          </div>
          <div class="user_logo">
            <a-popover placement="bottomRight" overlayClassName="m_popover">
              <template slot="content">
                <p class="pop_item" @click="updateInfo">
                  <a-icon type="setting" />账号设置
                </p>
                <p class="pop_item" @click="outLogin">
                  <a-icon type="logout" />安全退出
                </p>
              </template>
              <a-avatar
                class="user_pic"
                :src="
                  users && users.pic
                    ? QNPath + users.pic
                    : 'https://aitech.qingsteam.cn/ai/20200428/1588078300309.png'
                "
                style="cursor:pointer"
              />
            </a-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Login, Registe } from "@/assets/js/login.js";
import { delCookie } from "@/assets/js/operationCookie.js";
import { Message } from "ant-design-vue";
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "mHeader",
  data() {
    return {
      isSearch: false, //搜索框的展开和收起
      current: [],
      activeRoute: "",
      activePath: "",
      menus: [
        {
          text: "首页",
          key: "/",
          name: "Home",
        },
        {
          text: "课程中心",
          key: "/VOfflineClasses",
          name: "offlineClasses",
        },
        {
          text: "赛事中心",
          key: "/VEvent_Center",
          name: "Event_Center",
        },
        {
          text: "微认证",
          key: "/VMedal",
          name: "medal",
        },
        {
          text: "新闻资讯",
          key: "/VNews",
          name: "News",
        },
        {
          text: "Genius Brain",
          key: "/VGenius_Brain",
          name: "Genius_Brain",
        },
      ],
      keyWord: "",
      //控制导航栏的颜色
      //首页滚动条置顶的时候为透明色
      //其他时候为白色
      logos: [
        require("@/assets/imgs/logo.png"),
        require("@/assets/imgs/logo2.png"),
      ],
      current: null, //当前路由name
      isHomeAndTop: true,
      isScrollBar: false, //判断是否有滚动条true：有；false：没有\
      navtype: "", //导航类型
      uCodeUrl: '',
      isShow:-1,
      GeniusQcode:false,
      submitCount:0,
    };
  },
  props: {
    navBarList: Object,
  },
  computed: {
    ...mapState({
      proToken: (state) => state.indexStore.B_PRO_TOKEN,
      KeyWord: (state) => state.indexStore.KeyWord,
      users: (state) => state.indexStore.B_PRO_USERS,
      QNPath: (state) => state.indexStore.QiNiuPath,
    }),
  },
  created() {},
  mounted() {
    const that = this;
    //滚动条变化的监听
    window.addEventListener("scroll", function() {
      that.setNavType();
    });
    that.setNavOpt();
    that.setNavType();
    that.getUcodeUrl();
    that.getAmount();
    // that.getFccFlag();
  },
  watch: {
    $route: function(e, oldVal) {
      const that = this;
      that.activeRoute = e.name;
      that.activePath = e.path;
      if (e.name == "Home") {
        that.current = e.name;
      }
      that.setNavOpt();
      that.setNavType(); //路由监听变化晚于mounted

      if (that.$route.query.navtype) {
        //获取导航类型
        that.navtype = that.$route.query.navtype;
        if (that.$route.query.navtype == "classes") {
          //未登录跳转缺省页后，导航的选中状态
          that.activeRoute = "offlineClasses";
        } else if (that.$route.query.navtype == "medal") {
          that.activeRoute = "medal";
        }
      } else {
        that.navtype = "";
      }
    },
    // navBarList: function(newVal,oldVal){
    //   let fccFlag = newVal.navigationMenuList.find((item)=>{
    //     return item.moduleNameUser == 'FCC大赛'
    //   })
    //   console.log('fccFlag.accessAddress===='+fccFlag.accessAddress)
    //   localStorage.setItem('fccFlag',fccFlag.accessAddress)
    // },
  },
  methods: {
    //获取scrolltop，设置状态
    setNavType() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let isScroolTop = true;
      /*
				isHomeAndTop状态true，表示是首页并且滚动条距离顶部<=80;
				isHomeAndTop状态false，表示非首页或滚动条距离顶部>80;
				*/
      if (scrollTop > 80) {
        isScroolTop = false;
      } else {
        isScroolTop = true;
      }
      that.isHomeAndTop =
        that.current && that.current == "Home" && isScroolTop ? true : false;
      //dom重新加载完成后调用
      const routeName = that.$route.name;
      if (
        routeName == "offlineClasses" ||
        routeName == "videoOpen" ||
        routeName == "medal"
      ) {
        that.isScrollBar = true;
      } else {
        that.isScrollBar = false;
      }
    },
    //根据路由name，设置当前nav高亮
    setNavOpt() {
      const that = this;
      that.current = that.$route.name
        ? that.$route.name
        : that.$route.path == "/"
        ? "Home"
        : null;
    },

    //nav切换
    navCheck(navs, index) {
      if(navs.portalTwoModuleConfigVOList.length != 0){
        return;
      }else{
        //导航数据，下标
        this.activeRoute = navs.moduleCode;
        this.activePath = navs.accessAddress;
        // 如果跳转地址不是首页，带排序参数
        if(navs.moduleCode == "uCode") {
          if(this.proToken) {
            if(this.uCodeUrl){
              window.open(this.uCodeUrl);
            }else{
              window.open(navs.accessAddress);
            }
          }else{
            Message.warning('请登录', 1, () => {
              Login()
            })
          }
        }else if(navs.accessAddress.includes('https')) {
          window.open(navs.accessAddress);
        }else{
          this.$router.push({
            path: navs.accessAddress,
          });
          // 本地储存选中导航的下标，微认证和擎课堂,基地风采，活动中心运营图有用到
          localStorage.setItem('sort', index)
        }
      }
    },
    navToTwoModule(navs, index){
      //导航数据，下标
      this.activeRoute = navs.moduleCode;
      this.activePath = navs.accessAddress;
      // 如果跳转地址不是首页，带排序参数
      if(navs.moduleCode == "uCode") {
        if(this.proToken) {
          if(this.uCodeUrl){
            window.open(this.uCodeUrl);
          }else{
            window.open(navs.accessAddress);
          }
        }else{
          Message.warning('请登录', 1, () => {
            Login()
          })
        }
      }else if(navs.accessAddress.includes('https')) {
        window.open(navs.accessAddress);
      }else if(navs.moduleCode == '天才秀') {
        return false;
      }else{
         this.$router.push({
          path: navs.accessAddress,
        });
        // 本地储存选中导航的下标，微认证和擎课堂,基地风采，活动中心运营图有用到
        localStorage.setItem('sort', index)
      }
      this.isShow = !this.isShow;
    },
    showToggle(index,name) {
      this.isShow = index;
      localStorage.setItem('firstSort', index);
    },
    handleHide() {
      this.isShow = !this.isShow;
    },
    showGeniusQcode(name) {
      if(name == '天才秀'){
        this.GeniusQcode = true
      }
    },
    hideGeniusQcode(name){
      if(name === '天才秀'){
        this.GeniusQcode = false
      }
    },
    //搜索框展开
    clickSearch() {
      const that = this;
      if (!that.isSearch) {
        that.isSearch = true;
        that.$refs.inp_search.focus();
      } else {
        if(that.keyWord == ''){
          that.isSearch = false;
        }else{
          this.$store.commit("setKeyWord", that.keyWord);
          that.$router.push({
            path: "/Vsearch",
          });
          that.isSearch = false;
          that.keyWord = "";
        }
      }
    },
    //enter
    enter(e) {
      const that = this;
      if (e.keyCode == 13) {
        this.$store.commit("setKeyWord", that.keyWord);
        that.$router.push({
          path: "/Vsearch",
        });
        that.isSearch = false;
        that.keyWord = "";
      }
      if (e.keyCode == 46) {
        that.keyWord = "";
      }
    },
    goHome() {
      const that = this;
      that.$router.push({
        path: "/",
      });
    },
    getOldPageUrl() {
      let oldPageUrl = "";
      if (this.navtype == "medal") {
        // 如果路由类型是微认证
        oldPageUrl = `${window.location.origin}/VMedal`;
      } else if (this.navtype == "classes") {
        // 如果路由类型是课程中心
        oldPageUrl = `${window.location.origin}/VOfflineClasses`;
      }else if(this.navtype=='certificate'){
        // 授课证
        oldPageUrl = window.location.origin + "/VCertificate";
      }

      return oldPageUrl;
    },
    //去登陆
    async goToLogin() {
      //登录跳转方法
      let url = await this.getOldPageUrl();
      if (url) {
        Login(url);
      } else {
        Login();
      }
    },
    getAmount(){
      const that = this;
      let url = process.env.VUE_APP_LOGIN_QING_URL;
      axios({
        method: "get",
        url: `${url}user-task/taskCountByUser`,
        headers: {
          sessionToken: that.proToken,
        },
        // withCredentials: true, //表示允许跨域使用凭证cookie
      }).then((res) => {
        const data = res.data;
        this.submitCount = data.data.no_submit_count || 0
      });
    },
    //退出登录
    outLogin() {
      const that = this;
      let url = process.env.VUE_APP_OutLogin;
      axios({
        method: "post",
        url: `${url}/sso/logout`,
        headers: {
          sessionToken: that.proToken,
        },
        withCredentials: true, //表示允许跨域使用凭证cookie
      }).then((res) => {
        const msg = res.data;
        if (msg.status == 0) {
          //删除cookie中sessionToken
          localStorage.removeItem("B_PRO_TOKEN");
          localStorage.removeItem("B_PRO_USERS");
          delCookie("sessionToken");
          // Login();

          that.$store.commit("setToken", ""); //提交token
          Message.success("退出成功", 1, () => {
            if (that.activeRoute === "updateInfo") {
              //如果当前页面是账号设置，退出登录跳转到首页
              that.$router.push({ path: "/" });
            } else {
              location.reload(); //刷新页面
            }
          });
        }
      });
    },
    //修改用户信息
    updateInfo() {
      this.$router.push("/VUpdateInfo");
    },
    //个人空间
    goPersonalSpace() {
      const [agr, site] = window.location.href.split("://");
      const dominUrl = window.location.host;
      const userUrl = process.env.VUE_APP_PERSONAL_SPACE_URL;
      window.open(`${userUrl}?PAGE_URL=${agr}://${dominUrl}`);
    },

    hasScrollbar() {
      return (
        document.body.scrollHeight >
        (window.innerHeight || document.documentElement.clientHeight)
      );
    },

    // 跳转注册
    async toRegiste() {
      //登录跳转方法
      let url = await this.getOldPageUrl();
      if (url) {
        Registe(url);
      } else {
        Registe();
      }
    },

    getUcodeUrl() {
        let url=process.env.VUE_APP_LOGIN_Url;
        axios({
					method: 'post',
					url: `${url}/getUBTToken`,
					headers: {
						"sessionToken": this.proToken
					},
					withCredentials: true, //表示允许跨域使用凭证cookie
				}).then(res => {
          if(res.data.status == 0){
              let url = res.data.result.data.ubtURL;
              this.uCodeUrl = url;
          }
				});
    },
  },
};
</script>
<style lang="scss" scoped>
// 统一按钮颜色
$btnColor: rgb(93, 90, 237);

// 统一按钮样式
@mixin btnStyle {
  width: 96px;
  border: 2px solid $btnColor;
  font-size: 16px;
  margin-left: 30px;
  border-radius: 999px;
  line-height: 40px;
  text-align: center;
  height: 44px !important;
  box-sizing: border-box;
}

.dot{
  width:18px;
  height:18px;
  background: red;
  border-radius: 100%;
  position: absolute;
  right:-5px;
  top:-5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 12px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // background: #fff;
  z-index: 999;
  transition: all 0.2s ease;
  min-width: 1200px;

  .header_warpper {
    // overflow: hidden;
    // width: 1200px;
    // margin: 0 auto;
    display: flex;
    justify-content: center;

    .logo {
      width: 180px;
      height: 72px;
      line-height: 72px;
      float: left;
    }

    //以下是导航项
    .navs {
      float: left;
      line-height: 72px;

      ul {
        // overflow: hidden;
        padding: 0 25px 72px 25px;

        li {
          float: left;
          margin: 0 15px;
          font-size: 16px;
          color: #606978;
          cursor: pointer;
          position: relative;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // flex-direction: column;
        }

        .isHome {
          &:hover {
            transform: scale(1.1);
          }
        }

        .noHome {
          &:hover {
            transform: scale(1.1);
            color: #5d5aed !important;
            text-align: center;
          }
        }

        .activeNavisHome {
          transform: scale(1.1);
        }

        .activeNavNoHome {
          transform: scale(1.1);
          color: #5d5aed !important;
          text-align: center;
        }
      }
      .max-box{
        background: url('../assets/imgs/twoModulebg.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 55px;
        transform: translateX(-25%);
        .qcodeImg{
          position: absolute;
          left: 110px;
          top: 10px;
          width: 169px;
          border: 1px solid #e9e6e6;
          background: #fff;
        }
      }
      .hoverShow {
        display: flex;
        flex-direction: column;
        width: 120px;
        padding-bottom: 10px;
        font-size: 14px;
      }
      .hoverShow div {
        padding: 8px 10px;
        box-sizing: border-box;
        color: #606978;
        width: calc(100% - 13px);
        line-height: 14px;
        margin: 0 8px;
        text-align: center;
      }
      .hoverShow div:first-child{
        margin-top: 16px;
      }
      .hoverShow div:hover {
        background: #F5F5F5;
        width: calc(100% - 15px);
        color: #5D5AED;
      }
    }

    //搜索
    .search_box {
      float: right;
      overflow: hidden;
      margin-top: 15px;
      margin-left: 30px;

      .search {
        display: block;
        overflow: hidden;
        background-color: #fff;
        border-radius: 999px;
        width: 42px;
        transition: all 0.5s;

        .ant-input {
          float: left;
          height: 42px;
          line-height: 42px;
          border: none;
          padding: 0;
          width: calc(100% - 42px);
          background-color: rgba(0, 0, 0, 0);

          &:focus {
            border: none;
            box-shadow: none;
          }
        }

        .search_btn {
          float: right;
          display: block;
          width: 42px;
          height: 42px;
          line-height: 40px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0);
          text-align: center;
          cursor: pointer;
        }
      }
    }

    //以下是右侧用户信息
    .users {
      float: right;
      line-height: 72px;

      // 未登录样式
      .no_login {
        .btn {
          @include btnStyle;
          color: $btnColor;
          box-sizing: border-box;
        }
        .reg_btn {
          border: none;
        }

        :after {
          border: 0 none;
          opacity: 0;
          animation: none 0 ease 0 1 normal;
        }
      }

      // 已登录样式
      .yes_login {
        overflow: hidden;

        .my_room_box {
          float: left;
        }

        .btn {
          @include btnStyle;
          background-color: $btnColor;
          color: #fff;
        }

        :after {
          border: 0 none;
          opacity: 0;
          animation: none 0 ease 0 1 normal;
        }

        .user_logo {
          float: right;
          margin-left: 42px;

          .user_pic {
            height: 36px;
            width: 36px;
            overflow: hidden;
            border-radius: 999px;
            vertical-align: -36%;
          }
        }
      }
    }
  }
}

.m_popover {
  .pop_item {
    margin-bottom: 0px;
    padding: 10px 0;

    i {
      margin-right: 10px;
    }

    &:hover {
      color: rgb(93, 90, 237);
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1360px) {
  .navs {
    ul {
      li {
        font-size: 12px !important;
        margin: 0 10px !important;
      }
    }
  }
  .search_box{
    p{
      width: 40px !important;
    }
  }
  .no_login {
    margin-left: 12px;
    .btn {
      font-size: 12px !important;
      width: inherit !important;
    }
  }
  .header .header_warpper .users .yes_login .btn{
    margin-left: 10px;
    width: 80px;
    font-size: 12px;
  }
  .hoverShow {
    font-size: 10px;
  }
}

@media (min-width: 1360px) and (max-width: 1440px) {
  .navs {
    ul {
      li {
        font-size: 14px !important;
        margin: 0 10px !important;
      }
    }
  }
  .search_box{
    p{
      width: 40px !important;
    }
  }
  .header .header_warpper .users .yes_login .btn{
    margin-left: 10px;
    width: 80px;
    font-size: 12px;
  }
  .hoverShow {
    font-size: 12px;
  }
}
</style>
