import Vue from 'vue';
import Axios from 'axios';
import store from '../store/index.js'
import router from '../router'
import {Message} from 'ant-design-vue'
import {Login} from '@/assets/js/login.js';
import {delCookie,getCookie} from '@/assets/js/operationCookie.js';
var axios = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

/**
 * 请求失败后的错误统一处理
 * 请求失败的状态码
 */

// const errorHandle = (status, msg) => {
//   switch (status) {
//     case 401:
//       let lang=store.state.indexStore.lang;
//       Message.destroy();
//       Message.warning(msg,1,()=>{
//         localStorage.removeItem('B_PRO_TOKEN');
//         localStorage.removeItem('B_PRO_USERS');
//         delCookie('sessionToken');
//         //跳转到登录页方法
//         Login();
        
//       });
//     break;
//   }
// }

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么(后期在这里加上token)
    const token = getCookie('sessionToken');
    if (token) {
      config.headers.sessionToken = token;
    } else {
      let getSessionToken = localStorage.getItem('B_PRO_TOKEN');
      config.headers.sessionToken = getSessionToken;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
)

// 响应拦截器
axios.interceptors.response.use(
  // 请求成功
  (res) => {
    if (res.status === 200) {
        return Promise.resolve(res)
        // if (res.data.status === 401){
        //   errorHandle(res.data.status,res.data.msg);
        // }else{
        //   return Promise.resolve(res)
        // }
    } else {
      return Promise.reject(res)
    }
  },
  // 请求失败
  error => {
    const {
      response
    } = error;
    if (response) {
      // 请求已发出，但是不在200的范围
      // errorHandle(response.status, response.data.msg);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // store.commit('changeNetwork', false);
    }
  }
)

export default axios;
