<template>
  <div class="course" style="height:100%;font-size:0">
    <iframe ref="myIframe" :src="iframeUrl" frameborder="0" scrolling="auto" allowfullscreen="true" allowtransparency="true" width="100%" style="height:calc(100vh - 72px);"></iframe>
  </div>
</template>
<script>
import {queryUsers} from '@/assets/js/queryUsers.js';
import { mapState } from "vuex";
export default {
  name:'course',
  data(){
    return{
      iframeUrl:''
    }
  },
  computed: {
    ...mapState({
      proToken: (state) => state.indexStore.B_PRO_TOKEN,
    }),
  },
  created(){
    // 获取用户信息，如果有加载模块页面， 没有跳转登录页
    if (this.proToken) {
      this.setIframeUrl();
    } else {
      this.$router.push({
        path: "/VclassesCenterLogin",
        query: {
          navtype: "classes",
        },
      });
    }
  },
  methods:{
    setIframeUrl(){
      const querys=this.$route.query;
      let ids=querys.id?'&id='+querys.id:'',
          courseIds=querys.courseId?'&courseId='+querys.courseId:'',
          paperIds=querys.paperId?'&paperId='+querys.paperId:'',
          fileOrTest=querys.fileOrTest?'&fileOrTest='+querys.fileOrTest:'',
          courseTypeId=querys.courseTypeId?'&courseTypeId='+querys.courseTypeId:'',
          courseTypeIdTwo=querys.courseTypeIdTwo?'&courseTypeIdTwo='+querys.courseTypeIdTwo:'',
          seriesCourseId=querys.seriesCourseId?'&seriesCourseId='+querys.seriesCourseId:'',
          urltypes=querys.urltype?'&urltype='+querys.urltype:'';
      let url=process.env.VUE_APP_OFFLINE_PLAY_URL;
      let params=process.env.VUE_APP_IFRAME_PARAMS +'&PAGE_URL='+window.location.host+ids+courseIds+paperIds+fileOrTest;
      let params_series=process.env.VUE_APP_IFRAME_PARAMS +'&PAGE_URL='+window.location.host+ids+courseIds+paperIds+fileOrTest+
      courseTypeId+courseTypeIdTwo+seriesCourseId+urltypes;
      if(seriesCourseId != undefined){
        this.iframeUrl=url+'?'+params_series;
      }else{
        this.iframeUrl=url+'?'+params;
      }
      
    }
  }
}
</script>