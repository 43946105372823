var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header",style:({
    background: _vm.isHomeAndTop ? 'rgba(255,255,255,0)' : '#fff',
    boxShadow: _vm.isHomeAndTop
      ? 'rgba(0, 0, 0, 0) 0px 0px 0px 0px'
      : 'rgba(0, 0, 0, 0.16) 0px 1px 5px 0px',
    paddingRight: _vm.isScrollBar ? '17px' : '0px',
  })},[(_vm.navBarList.navigationMenuList)?_c('div',{staticClass:"header_warpper"},[_c('div',{staticClass:"logo"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.isHomeAndTop
            ? _vm.QNPath + _vm.navBarList.siteLogoOne
            : _vm.QNPath + _vm.navBarList.siteLogoTwo,"width":"180","alt":""},on:{"click":_vm.goHome}})]),_c('div',{staticClass:"navs"},[_c('ul',_vm._l((_vm.navBarList.navigationMenuList),function(item,index){return _c('li',{key:item.id,class:((_vm.isHomeAndTop
                ? item.moduleCode == _vm.activeRoute
                  ? 'activeNavisHome'
                  : ''
                : item.moduleCode == _vm.activeRoute
                ? 'activeNavNoHome'
                : '') + " " + (_vm.isHomeAndTop && _vm.activePath == '/' ? 'isHome' : 'noHome')),style:({ color: _vm.isHomeAndTop ? '#fff' : '#606978' }),on:{"click":function($event){return _vm.navCheck(item, index)},"mouseover":function($event){return _vm.showToggle(index,item.moduleNameUser)},"mouseout":_vm.handleHide}},[_vm._v(" "+_vm._s(item.moduleNameUser)+" "),_c('block',{directives:[{name:"show",rawName:"v-show",value:(item.portalTwoModuleConfigVOList && item.portalTwoModuleConfigVOList.length != 0 && index === _vm.isShow),expression:"item.portalTwoModuleConfigVOList && item.portalTwoModuleConfigVOList.length != 0 && index === isShow"}]},[_c('div',{staticClass:"max-box hoverShow"},[_vm._l((item.portalTwoModuleConfigVOList),function(twoModule,index){return _c('div',{key:index,on:{"click":function($event){return _vm.navToTwoModule(twoModule, index)},"mouseover":function($event){return _vm.showGeniusQcode(twoModule.moduleNameUser)},"mouseout":function($event){return _vm.hideGeniusQcode(twoModule.moduleNameUser)}}},[_vm._v(" "+_vm._s(twoModule.moduleNameUser)+" ")])}),(_vm.GeniusQcode)?_c('div',{staticClass:"qcodeImg"},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require("../assets/imgs/qcode.jpg"),"alt":""}})]):_vm._e()],2)])],1)}),0)]),(_vm.navBarList.showSearch)?_c('div',{staticClass:"search_box"},[_c('p',{staticClass:"search",style:({
          width: _vm.isSearch ? '242px !important' : '',
          paddingLeft: _vm.isSearch ? '14px' : '0',
          background: !_vm.isHomeAndTop ? '#F8F9FA' : '',
        })},[_c('a-input',{ref:"inp_search",attrs:{"placeholder":"搜索课程、勋章、赛事、资讯"},on:{"keydown":_vm.enter},model:{value:(_vm.keyWord),callback:function ($$v) {_vm.keyWord=$$v},expression:"keyWord"}}),_c('span',{staticClass:"search_btn",on:{"click":_vm.clickSearch}},[_c('img',{attrs:{"src":require("../assets/imgs/seach.png"),"alt":""}})])],1)]):_vm._e(),_c('div',{staticClass:"users"},[(!_vm.proToken)?_c('div',{staticClass:"no_login"},[_c('a-button',{staticClass:"btn login_btn",style:({
            color: _vm.isHomeAndTop ? '#fff' : '',
            borderColor: _vm.isHomeAndTop ? '#fff' : '',
            background: _vm.isHomeAndTop ? 'none' : '',
          }),on:{"click":_vm.goToLogin}},[_vm._v("登录")])],1):(_vm.proToken)?_c('div',{staticClass:"yes_login"},[(_vm.navBarList.showPersonalSpace)?_c('div',{staticClass:"my_room_box"},[_c('a-button',{staticClass:"btn my_room",style:({
              color: _vm.isHomeAndTop ? '#fff' : '',
              borderColor: _vm.isHomeAndTop ? '#fff' : '',
              background: _vm.isHomeAndTop ? 'none' : '',
            }),on:{"click":_vm.goPersonalSpace}},[_vm._v(" 个人空间 "),(_vm.submitCount)?_c('div',{staticClass:"dot"},[_vm._v(_vm._s(_vm.submitCount))]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"user_logo"},[_c('a-popover',{attrs:{"placement":"bottomRight","overlayClassName":"m_popover"}},[_c('template',{slot:"content"},[_c('p',{staticClass:"pop_item",on:{"click":_vm.updateInfo}},[_c('a-icon',{attrs:{"type":"setting"}}),_vm._v("账号设置 ")],1),_c('p',{staticClass:"pop_item",on:{"click":_vm.outLogin}},[_c('a-icon',{attrs:{"type":"logout"}}),_vm._v("安全退出 ")],1)]),_c('a-avatar',{staticClass:"user_pic",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.users && _vm.users.pic
                  ? _vm.QNPath + _vm.users.pic
                  : 'https://aitech.qingsteam.cn/ai/20200428/1588078300309.png'}})],2)],1)]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }