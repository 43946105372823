<template>
  <div class="course" style="height:100%;font-size:0">
    <iframe ref="myIframe" :src="iframeUrl" frameborder="0" scrolling="auto" allowfullscreen="true" allowtransparency="true" width="100%" style="height:calc(100vh - 72px);"></iframe>
  </div>
</template>
<script>
import {queryUsers} from '@/assets/js/queryUsers.js';
import { mapState } from "vuex";
export default {
  name:'course',
  data(){
    return{
      iframeUrl:''
    }
  },
  computed: {
    ...mapState({
      proToken: (state) => state.indexStore.B_PRO_TOKEN,
    }),
  },
  created(){
    // 获取用户信息，如果有加载模块页面， 没有跳转登录页
    if (this.proToken) {
      this.setIframeUrl();
    } else {
      this.$router.push({
        path: "/VclassesCenterLogin",
        query: {
          navtype: "classes",
        },
      });
    }
  },
  methods:{
    setIframeUrl(){
      let courseId=this.$route.query.id;
      let courseTypeId=this.$route.query.courseTypeId;
      let courseTypeIdTwo=this.$route.query.courseTypeIdTwo;
      let seriesCourseId = this.$route.query.seriesCourseId;
      let url=process.env.VUE_APP_OFFLINE_DETAILS_URL;
      let params= '';
      if(seriesCourseId != undefined){
        params=process.env.VUE_APP_IFRAME_PARAMS +'&PAGE_URL='+window.location.host+'&id='+courseId+
        '&courseTypeId='+courseTypeId+'&courseTypeIdTwo='+courseTypeIdTwo+'&seriesCourseId='+seriesCourseId;
      }else{
        params=process.env.VUE_APP_IFRAME_PARAMS +'&PAGE_URL='+window.location.host+'&id='+courseId;
      }
      this.iframeUrl=url+'?'+params
      
    }
  }
}
</script>