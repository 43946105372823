<template>
<!-- v-html="footConfig.bottomConfig" -->
	<div class="page_footer" v-html="footConfig.bottomConfig">
		<!-- <div class="footer_container" style="background: #5D5AED">
			<div class="footer_container" style="width: 1200px; height: 88px; margin: auto; position: relative;">
				<div class="footer_left">
					<p class="left_box" 
						style="display: flex; 
						align-items: center; 
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						margin: auto;">
						<img 
							class="phone_icon" 
							src="https://test.ubgenius.cn//ai/ue/202008201448171151440.png" 
							width = "25" 
							height = "25" 
							margin-right ="10px"
							alt=""> 
						
						<span
							style="font-size: 28px;
							color: #fff;
							margin-left: 10px;
							">021-64827505</span>
					</p>
				</div>
				<div class="footer_center" style="  
					text-align: center;
					height: 88px;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					padding: 15px 0;
					box-sizing: border-box;
				">
					<p style="color: #fff; font-size: 12px; line-height: 18px;">上海市杨浦区伟成路70号创智天地企业中心1号楼8楼</p>
					<p style="color: #fff; font-size: 12px; line-height: 18px;">上海优必杰教育科技信息有限公司©2018-2020 · 沪ICP备17047230号 · 沪公安网备3101002002830号</p>
				</div>
				<div class="footer_right" style="display: flex; margin-left: 15px;
					position: absolute;
					right: 0;
					bottom: 20px;">
					<div style="display:flex">
						<div class="right_item" style="text-align: center;
						color: #fff;
						margin-left: 15px;">
							<img src="http://shjiading.qingsteam.cn/static/img/code.24ef6c7.jpeg" alt="" width="120" height="120">
							<p style="margin-top: 5px; font-size: 12px;">嘉定青少年科创集散地</p>
						</div>
					</div>
				</div>
				<div class="left-img" style="position: absolute;top: -75px;left: 0">
					<img src="http://shjiading.qingsteam.cn/static/img/logo2.06a05bf.png" alt="">
				</div>
			</div>
		</div> -->
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	export default {
		name: 'page_footer',
		data() {
			return {
				footNav: [{
						text: '关于优必杰教育',
						key: '0'
					},
					{
						text: '擎课堂',
						key: '1'
					},
					{
						text: '嘉定集散地',
						key: '2'
					},
					{
						text: '余杭集散地',
						key: '3'
					},
					{
						text: '常用工具',
						key: '4'
					}
                ],
                uls: '<a href="http://www.ubgenius.cn/" target="_blank"><li>哈哈</li></a>'
			}
		},
		props: {
			footConfig: Object,
        },
        mounted() {
            // 在vue 中，添加html元素，并在元素中添加点击事件，用原生的onclick来触发事件，将事件挂在window上
			// window.footerOpenUrl = this.footerOpenUrl;
			// let img = document.querySelector(".footer_container")
			// console.log(img);
			// for (let i = 0; i < img.length; i++) {
			// 	const element = img[i];
			// 	element.style.width = "100%"
			// }
        },
		computed: {
			...mapState({
                token: state => state.indexStore.B_PRO_TOKEN,
			})
		},
		methods: {
			//页尾跳转
			// footerOpenUrl: function(key) {
			// 	let pageToken = this.token;
			// 	let url;
			// 	if (key == '0') {
			// 		url = pageToken ? "http://www.ubgenius.cn/#/" + "?TOKEN=" + pageToken : "http://www.ubgenius.cn/";
			// 	} else if (key == '1') {
			// 		url = pageToken ? "https://changeducation.cn/" + "?TOKEN=" + pageToken : "https://changeducation.cn/";
			// 	} else if (key == '2') {
			// 		url = pageToken ? "http://shjiading.qingsteam.cn/#/" + "?TOKEN=" + pageToken : "http://shjiading.qingsteam.cn/";
			// 	} else if (key == '3') {
			// 		url = pageToken ? "http://AIyh.qingsteam.cn/#/" + "?TOKEN=" + pageToken : "http://AIyh.qingsteam.cn/";
			// 	} else if (key == '4') {
			// 		url = "https://www.changeducation.cn/download.html";
			// 	}
			// 	window.open(url);
            // },
		}
    }
</script> 
<style lang="scss" scoped>
	@import "../style/mixin.scss";
	//字体颜色
	$textColor:#fff;
	//底部背景色
	$footerBgColor:#5D5AED;

	.page_footer {
		// background-color: $footerBgColor;
		// height: 88px;
		// margin-top: 70px;
		// .footer_container {
		// 	@include wh(1200px, 88px);
		// 	margin: auto;
		// 	position: relative;
		// 	.footer_left {
		// 		.left_box{
		// 			height: 88px;
        //             @include fa();
                    
		// 			position: absolute;
		// 			top: 0;
		// 			bottom: 0;
		// 			left: 0;
        //             margin: auto;
		// 			.phone_icon {
		// 				@include wh(25px, 25px);
		// 				margin-right: 10px;
		// 			}
		// 			span {
		// 				font-size: 24px;
		// 				color: #fff;
		// 			}
		// 		}
		// 	}

		// 	.footer_center {
		// 		text-align: center;
		// 		letter-spacing: 1.5px;
		// 		height: 88px;
		// 		display: flex;
		// 		flex-direction: column;
		// 		justify-content: space-evenly;
		// 		ul {
		// 			text-align: center;
		// 			// margin-bottom: 8px;
		// 			line-height: 19px;

		// 			li {
		// 				display: inline;
		// 				color: $textColor;
		// 				position: relative;
		// 				padding-right: 8px;
		// 				padding-left: 8px;
		// 				font-size: 14px;
		// 				cursor: pointer;

		// 				&::after {
		// 					position: absolute;
		// 					right: 0;
		// 					top: calc(50% - 20%);
		// 					width: 1px;
		// 					height: 60%;
		// 					background: #fff;
		// 					content: "";
		// 				}

		// 				&:last-child {
		// 					&::after {
		// 						width: 0;
		// 					}
		// 				}
		// 			}
		// 		}

		// 		p {
		// 			color: $textColor;
		// 			font-size: 12px !important;
		// 			line-height: 18px;
		// 		}
		// 	}

		// 	.footer_right {
		// 		display: flex;
		// 		margin-left: 15px;
		// 		position: absolute;
		// 		right: 0;
		// 		bottom: 10px;
		// 		.right_item {
		// 			text-align: center;
		// 			color: #fff;
		// 			margin-left: 15px;
		// 			img {
		// 				@include wh(120px, 120px, 4px);
		// 				margin-bottom: 5px;
		// 			}
		// 		}

		// 	}
		// }

	}
</style>
