import request from '@/utils/http.js'

export function queryStudentAut(options) {
    return request({
        url: '/schoolManager/queryStudentAuth',
        method: 'post',
        data: options
    })
}

//token获取用户信息
export function getUserInfo(options) {
    return request({
        url: '/businessPro/getUserInfo',
        method: 'post',
        data: options
    })
}

