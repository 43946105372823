//获取cookie
export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
   }
  return "";
}

//删除cookie--sessionToken
export function delCookie (name) {
  document.cookie = ''+ name +'=; expires='+ new Date(0).toUTCString() + '; path=/; Domain=.qingsteam.cn';
  // document.cookie = 'sessionToken=; expires='+ new Date(0).toUTCString() + '; path=/; Domain=.changeducation.cn';
}