<template>
  <div class="subscription" style="height: 100%; font-size: 0">
    <iframe
      ref="myIframe"
      :src="iframeUrl"
      frameborder="0"
      scrolling="auto"
      width="100%"
      allowfullscreen="true"
      allowtransparency="true"
      style="height: calc(100vh - 4.5rem)"
      allow="autoplay"
    >
    </iframe>
  </div>
</template>
<script>
import { Login, Registe } from "@/assets/js/login.js";
import Footer from "@/components/Footer_q.vue";
import { mapState } from "vuex";
export default {
  name: "subscription",
  data() {
    return {
      iframeUrl: "",
    };
  },
  components: {
    MFooter: Footer,
  },
  computed: {
    ...mapState({
      proToken: (state) => state.indexStore.B_PRO_TOKEN,
    }),
  },
  created() {
    this.setIframeUrl()
  },
  mounted() {
    window.addEventListener('message',e=>{
			if(e.data.msg==='subsciptnumber'){ 
        // 判断是否登录
        let url = this.getOldPageUrl();
        if (url) {
          Login(url);
        } else {
          Login();
        } 
			} 
		}); 
  },
  methods: {
	  setIframeUrl() {
      let id=this.$route.query.id;
      let url = process.env.VUE_APP_SUBSCRIPTION_NUMBER_URL;
      let params =process.env.VUE_APP_IFRAME_PARAMS + "&PAGE_URL=" + window.location.host+"&sessionToken=" + this.proToken+"&id=" + id;
      this.iframeUrl = url + "?" + params;
    },
    getOldPageUrl() {
      let id=this.$route.query.id;
      let oldPageUrl = "";
      oldPageUrl = `${window.location.origin}/VSubscriptionNumber?id=${id}`;
      return oldPageUrl;
    },
  }
};
</script>
<style scoped></style>
