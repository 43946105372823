// JavaScript source code

//������ѯ
import request from '@/utils/http.js';

//��ѯ�γ�
export function getToken(options) {
    return request({
        url: '/qiniuFile/getToken',
        method: 'post',
        data: options
    })
}
