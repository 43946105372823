const OfflineClasses = () => import("@/pages/courses/OfflineClasses.vue");
const Medal = () => import("@/pages/medals/Medal.vue")
import OfflineClassesDetail from '@/pages/courses/OfflineClassesDetail.vue';
import CourseSeriesDetail from '@/pages/courses/CourseSeriesDetail.vue';
import VideoOpen from '@/pages/courses/VideoOpen.vue';
import MedalDetails from '@/pages/medals/MedalDetails.vue';
import UpdateInfo from '@/pages/update/updateInfo.vue';

const SubscriptionCenter = ()=>import('@/pages/subscription/subscriptionCenter.vue'); // 订阅中心
import SubscriptionDetail from '@/pages/subscription/subscriptionDetail.vue'; // 订阅号详情
import SubscriptionNumber from '@/pages/subscription/subscriptionNumber.vue'; // 订阅号

const iframeRoutes=[
  {
    path:'/VOfflineClasses',
    name:'offlineClasses',
    component:OfflineClasses
  },
  {
    path:'/VCourseSeriesDetail',
    name:'courseSeries',
    component:CourseSeriesDetail
  },
  {
    path:'/VOfflineClassesDetail',
    name:'offlineClasses',
    component:OfflineClassesDetail
  },
  {
    path:'/VVideoOpen',
    name:'videoOpen',
    component:VideoOpen
  },
  {
    path:'/VMedal',
    name:'medal',
    component:Medal
  },
  {
    path:'/VMedalDetails',
    name:'medal',
    component:MedalDetails
  },
  {
    path:'/VUpdateInfo',
    name:'updateInfo',
    component:UpdateInfo
  },
  {
    path: '/VSubscriptionCenter', // 订阅中心
    name: 'Subscription_Center',
    component: SubscriptionCenter
  },
  {
    path: '/VSubscriptionDetail', // 订阅号详情
    name: 'Subscription_Center',
    component: SubscriptionDetail
  },
  {
    path: '/VSubscriptionNumber', // 订阅号
    name: 'Subscription_Center',
    component: SubscriptionNumber
  },
]

export default iframeRoutes;