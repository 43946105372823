import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Router from "vue-router";
import store from "./store";
import { getUserInfo } from "@/apis/user.js";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import { delCookie } from "@/assets/js/operationCookie.js";

Vue.use(VueVideoPlayer);
Vue.use(VueAwesomeSwiper);

import {
  ConfigProvider,
  Layout,
  Carousel,
  Button,
  Popover,
  Avatar,
  Icon,
  Input,
  Tabs,
  Pagination,
  Divider,
  Modal,
  Spin,
  Col,
  Row,
  PageHeader,
  Breadcrumb,
  Empty,
  Form,
  Select,
  Upload,
  Message
} from "ant-design-vue";
Vue.use(ConfigProvider);
Vue.use(Layout);
Vue.use(Carousel);
Vue.use(Button);
Vue.use(Popover);
Vue.use(Avatar);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Tabs);
Vue.use(Pagination);
Vue.use(Divider);
Vue.use(Modal);
Vue.use(Spin);
Vue.use(Col);
Vue.use(Row);
Vue.use(PageHeader);
Vue.use(Breadcrumb);
Vue.use(Empty);
Vue.use(Form);
Vue.use(Select);
Vue.use(Upload);
Vue.prototype.$message=Message;
//路由守卫
router.beforeEach((to, from, next) => {
  baiduScript();
  //获取store中的tkoen
  let b_pro_token = store.state.indexStore.B_PRO_TOKEN;
  //获取用户信息
  let users = store.state.indexStore.B_PRO_USERS;
  if (b_pro_token) {
    if (users) {
      next();
    } else {
      loginFun(next);
    }
  } else {
    next();
  }
});

const routerPush = Router.prototype.push;
const routerReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch((error) => error);
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 路由变化跳转页面回到顶部
// router.afterEach((to,from,next) => {
//   window.scrollTo(0,0);
// })

//根据token登录，获取用户信息
function loginFun(next) {
  getUserInfo({})
    .then((res) => {
      const msg = res.data;
      if (msg.status == 0) {
        // console.log(msg)
        store.commit("setUsers", msg.result);
        next();
      } else {
        // 以前token存在，没有获取用户信息清除以前token
        delCookie("sessionToken");
        location.reload(); //刷新页面
        next();
      }
    })
    .catch((err) => {
      next();
    });
}

//百度埋点
function baiduScript() {
  var _hmt = _hmt || [];
  (function() {
    // var hm = document.createElement("script");
    // hm.src = "https://hm.baidu.com/hm.js?a363efbf14ffd9bca947d7ca6507a163";
    // var s = document.getElementsByTagName("script")[0];
    // s.parentNode.insertBefore(hm, s);
    var src = "https://hm.baidu.com/hm.js?a363efbf14ffd9bca947d7ca6507a163";
    var s = document.getElementsByTagName("script")[0];
    s.setAttribute("src", src);
  })();
}
