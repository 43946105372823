const Certificate =()=>import('@/pages/certificate/index.vue');
const CertificateDetails =()=>import('@/pages/certificate/certificateDetails.vue');
const CworkDetails =()=>import('@/pages/certificate/workDetails.vue');
const CertificateApply=()=>import('@/pages/certificate/certificateApply.vue');

//证书
const routers=[
  {
    path:'/VCertificate',
    name:'Certificate',
    component:Certificate
  },
  {
    path:'/VCertiDetails',
    name:'CertificateDetails',
    component:CertificateDetails
  },
  {
    path:'/VCworkDetails',
    name:'CworkDetails',
    component:CworkDetails
  },
  {
    path:'/VCertificateApply',
    name:'CertificateApply',
    component:CertificateApply
  }
]

export default routers;