<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <div class="container">
        <header :style="{ height: isHome ? '' : '72px' }">
          <m-header :navBarList="navConfig" />
        </header>
        <main id="main">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </main>
      </div>
      <div
        v-if="
          atRouterName == 'offlineClasses' ||
          atRouterName == 'offlineClassesDetail' ||
          atRouterName == 'videoOpen' ||
          atRouterName == 'medal' ||
          atRouterName == 'medalDetails'||
          atRouterName == 'Subscription_Center' ||
          atRouterName == 'subscriptionDetail' ||
          atRouterName == 'subscriptionNumber'
            ? false
            : true
        "
      >
        <m-footer :footConfig="navConfig" />
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer_q.vue";
import { mapState, mapMutations } from "vuex";
import { getToken } from "@/apis/App.js";
import { getNavBar } from "@/apis/Home.js";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  name: "APP",
  data() {
    return {
      isHome: true,
      atRouterName: "",
      locale: zhCN,
      navConfig: {}, //头部导航配置
    };
  },
  watch: {
    $route(e) {
      this.atRouterName = e.name;
      if (e.name == "Home") {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
  },
  created() {
    this.atRouterName = this.$route.name;
    if (this.QiNiuPath == "") {
      this.getQiNiuPath();
    }
    this.setIsHome();
    this.getNavBar();

    // 移动端访问pc首页跳转到移动端首页
    // if (this._isMobile() && this.$route.path == "/") {
    //   window.location.href = window.location.href + "M";
    // }

  },
  computed: {
    ...mapState({
      commonData: (state) => state.indexStore.commonData,
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
      proToken: (state) => state.indexStore.B_PRO_TOKEN,
    }),
  },
  components: {
    MHeader: Header,
    MFooter: Footer,
  },
  methods: {
    changeParentState() {
      this.$store.commit("setCommonData", {
        parent: 1,
      });
    },
    setIsHome() {
      const that = this;
      if (that.$route.name == "Home") {
        that.isHome = true;
      } else {
        that.isHome = false;
      }
    },

    getQiNiuPath() {
      var params = {};
      getToken(params).then((res) => {
        let msg = res.data != undefined && res.data != null ? res.data : null;
        if (msg != null && msg.status == 0) {
          this.$store.commit("setQiNiu", msg.result.domain);
        }
      });
    },
    // 导航栏配置
    getNavBar() {
      getNavBar({}).then((res) => {
        if (res.data.status == 0) {
          let config = res.data.result;
          let { otherMenuList } = config;
          config.showPersonalSpace = false;
          config.showSearch = false;

          otherMenuList.forEach((e) => {
            //判断其他模块是否存在
            if (e.moduleCode == "personalSpace") {
              // 判断个人空间
              config.showPersonalSpace = true;
            } else if (e.moduleCode == "search") {
              //判断搜索
              config.showSearch = true;
            }
          });
          localStorage.setItem('siteId',config.id)
          this.navConfig = config;
          this.$store.commit("setPortalConfig", this.navConfig); //全局存入导航配置
          this.changeTitle(config); // 改变标题
          this.skipMobile(config);
        }
      });
    },
    // 改变标题
    changeTitle(config) {
      // let iconUrl = "";
      // let titleTxt = "";
      // if (config.partyId == "2") {
      //   // 嘉定
      //   titleTxt = "嘉定青少年科创集散基地";
      //   iconUrl = "https://aitech.qingsteam.cn/ai/jiading_logo.ico";
      // } else if (config.partyId == "25") {
      //   //余杭
      //   titleTxt = "余杭区人工智能教育";
      //   iconUrl = "https://aitech.qingsteam.cn/ai/yhlogo.ico";
      // } else if (config.partyId == "41") {
      //   //余杭高级中学
      //   titleTxt = "余杭实验中学";
      //   iconUrl = "https://aitech.qingsteam.cn/ai/yhsy.ico";
      // } else if (config.partyId == "43"){
      //   // 七宝中学
      //   titleTxt = "上海市七宝中学";
      //   iconUrl = "https://aitech.qingsteam.cn/ai/qbhs.ico";
      // }else if(config.partyId == "29"){
      //   // 擎课堂pro
      //   titleTxt = "擎课堂-PRO";
      // }else {
      //   return;
      // }
      document.title = config.siteName;
      document.querySelectorAll("#icon_pro")[0].setAttribute("href", this.QiNiuPath + config.siteIcon);
    },

    _isMobile() {
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        return true; // 手机端
      } else {
        return false; // alert('PC端')
      }
    },
    // 跳转移动端
    skipMobile(config){
      // 移动端访问pc首页跳转到移动端首页
      // if (this._isMobile() && this.$route.path == "/" && config.partyId != "41" && config.partyId != "43") {
      //   window.location.href = window.location.href + "M";
      // }
      if (this._isMobile() && this.$route.path == "/" && config.openMobile == 1) {
        window.location.href = window.location.href + "M";
      }
    }
  },
};
</script>

<style lang="scss">
@import "./style/common.scss";
#app {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  flex-direction: column;
}
body{
  overflow: auto !important;
  width: 100% !important;
}
/* 首页 */
.home .ant-carousel .slick-dots li.slick-active button {
  width: 36px;
  height: 11px;
  background: rgba(93, 90, 237, 0.8);
  border-radius: 6px;
}
.home_news_container .ant-carousel .slick-dots li.slick-active button {
  width: 36px;
  height: 11px;
  background: #fff;
  border-radius: 6px;
}

.home .ant-carousel .slick-dots {
  margin-bottom: 10px;
}

.home .ant-carousel .slick-dots li button {
  width: 11px;
  height: 11px;
  margin-left: 5px;
  border-radius: 50%;
}

/* 搜索 */
#Search .ant-tabs-nav {
  font-size: 18px;
}

#Search .ant-tabs-tab {
  transition: all 0.4s ease;
  text-rendering: auto;
}

// #Search .ant-tabs-nav .ant-tabs-tab-active {
//   color: #5d5aed;
// }

// #Search .ant-tabs-nav .ant-tabs-tab:hover {
//   color: #5d5aed;
// }

// #Search .ant-tabs-ink-bar {
//   background-color: #5d5aed;
// }

// #Search .ant-pagination-item-active a {
//   color: #5d5aed;
// }

// #Search .ant-pagination-item-active {
//   border-color: #5d5aed;
// }

// #Search .ant-pagination-item:focus a,
// .ant-pagination-item:hover a {
//   color: #5d5aed;
// }

// #Search .ant-pagination-item:focus,
// .ant-pagination-item:hover {
//   border-color: #5d5aed;
// }

// #Search .ant-pagination-prev:focus .ant-pagination-item-link,
// .ant-pagination-next:focus .ant-pagination-item-link,
// .ant-pagination-prev:hover .ant-pagination-item-link,
// .ant-pagination-next:hover .ant-pagination-item-link {
//   color: #5d5aed;
//   border-color: #5d5aed;
// }

/* 新闻 */
.News .ant-carousel .slick-dots li.slick-active button {
  width: 36px;
  height: 11px;
  background: #fff;
  border-radius: 6px;
}

.News .ant-carousel .slick-dots {
  margin-bottom: 17px;
  right: 15px;
  width: auto;
}

.News .ant-carousel .slick-dots li button {
  width: 11px;
  height: 11px;
  margin-left: 5px;
  border-radius: 50%;
}

// 首页中新闻轮播样式
.home_news_container .ant-carousel .slick-dots li.slick-active button {
  width: 36px;
  height: 11px;
  background: #fff;
  border-radius: 6px;
}

.home_news_container .ant-carousel .slick-dots {
  margin-bottom: 17px;
  right: 15px;
  width: auto;
}

// 视频播放开关
.video-js {
  background-color: #fff !important;
}
.video-js .vjs-big-play-button {
  display: none !important;
}
.video-js .vjs-poster {
  background-size: cover;
  background-position-y: -40px;
}

// 弹窗关闭样式
.ant-modal-close {
  top: -10px;
  right: -13px;
}
</style>
