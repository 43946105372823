//统一登录方法
export function Login(oldPageURL = window.location.href){
  let url=process.env.VUE_APP_LOGIN_Url;
  // let oldPageURL=window.location.href;
  let code=29;
  window.location.href=url+"/html/users/ubglogin.html?systemCode="+code+"&"+oldPageURL;
}

// 注册
export function Registe(oldPageURL = window.location.href) {
  let url = process.env.VUE_APP_LOGIN_Url;
  // let oldPageURL = window.location.href;
  let code = 29;
  window.location.href = url + "/html/users/ubgregister.html?systemCode=" + code + "&" + oldPageURL;
}
