import { getCookie } from '@/assets/js/operationCookie.js';
const state = {
    B_PRO_TOKEN: localStorage.getItem('B_PRO_TOKEN') ? localStorage.getItem('B_PRO_TOKEN') : getCookie('sessionToken') ? getCookie('sessionToken') : null,
    // commonData: {
    //     parent: 1
    // },
    QiNiuPath:"",
    KeyWord: "",
    B_PRO_USERS:localStorage.getItem('B_PRO_USERS') ? JSON.parse(localStorage.getItem('B_PRO_USERS')) : null,
    PortalConfig: {},
    MODELCODE: {
        HOME: "Home", //首页
        CLASSES: "offlineClasses", //课程中心
        NEWS: "News", //新闻资讯
        MEDAL: "medal", //微认证
        ACTIVITY: "Event_Center", //活动中心
        BASE: "baseStar", //基地风采
    }
}

const mutations={ 
	//设置token
	setToken:function(state,params){
		state.B_PRO_TOKEN=params;
		localStorage.setItem('B_PRO_TOKEN',params);
	},
	// setCommonData(state, val){
    // state.commonData = val;
    // },
    //qn
    setQiNiu: function (state, params) {
        state.QiNiuPath = params;

    },
    setKeyWord: function (state, params) {
         state.KeyWord = params;

    },
    setUsers:function(state,params){
        state.B_PRO_USERS=params;
        localStorage.setItem(JSON.stringify(params));
    },
    setPortalConfig: function (state, params) {
        state.PortalConfig = params;
    },
    
}

const actions={
	//异步调用setToken
	actSetToken:function(context,options){
		context.commit('setToken',options);	
	}
}

export default {
	state,
	mutations,
	actions
}