<template>
    <div>
        <iframe :src="url" frameborder="0" scrolling="no" width="100%" style="height:1000px;"></iframe>
    </div>
</template>
<script>
    import { queryUsers } from '@/assets/js/queryUsers.js';
    export default {
        name: 'update',
        data() {
            return {
                url: ''
            }
        },
        created() {
            const that = this;
            queryUsers().then((res) => {
                that.url = process.env.VUE_APP_UPDATE_URL + '?' + process.env.VUE_APP_IFRAME_PARAMS;
            });
            that.RefreshFn();
        },
        methods: {
            RefreshFn: function () {
                var that = this
                window.addEventListener('message', function (event) {
                    if (event.data.refresh) {
                        // console.log(123456789)
                        window.location.reload();
                    }
                })
            },
        }
    }
</script>