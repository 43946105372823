import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = ()=>import('@/views/Home.vue')
const Search = ()=>import('@/pages/Search.vue')
const News = ()=>import('@/pages/News.vue')
const NewsDetails = ()=>import('@/pages/NewsDetails.vue')
const Genius_Brain = ()=>import('@/pages/Genius_Brain.vue')
const AIMicroFlyer = ()=>import('@/pages/AIMicroFlyer.vue')
const GeniusBrainYuHang = ()=>import('@/pages/GeniusBrain-yuhang.vue')
const Event_Center = ()=>import('@/pages/Event_Center.vue');
const JiaDing = () => import('@/pages/aboutUs/jiading.vue');
const YuHang = () => import('@/pages/aboutUs/yuhang.vue');
const ClassesCenterLogin = () => import('@/pages/classesCenterLogin.vue');
const BaseStar = () => import('@/pages/baseStar/baseStar.vue');
const WorksDetail = () => import('@/pages/baseStar/worksDetail.vue');

//所有iframe组件路由
import iframeRoutes from './modules';

//证书所有组件
import CerModul from './modules/certificate';

Vue.use(VueRouter)

const routes = [
	{
		path:'/',
		name:'Home',
    component: Home,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/Vsearch',
    name: 'Search',
    component: Search
  },
  {
    path: '/VNews',
    name: 'News',
    component: News
  }
  ,
  {
    path: '/VNewsDetails',
    name: 'NewsDetails',
    component: NewsDetails,
  }
  ,
  {
    path: '/VGenius_Brain',
    name: 'Genius_Brain',
    component: Genius_Brain
  },
  {
    path: '/VAIMicroFlyer',
    name: 'AIMicroFlyer',
    component: AIMicroFlyer
  },
  {
    path: '/VGeniusBrainYuHang',
    name: 'Genius_Brain',
    component: GeniusBrainYuHang
  },
  {
    path: '/VEvent_Center',
    name: 'Event_Center',
    component: Event_Center
  },
  {
    path: '/VclassesCenterLogin',
    name: 'classesCenterLogin',
    component: ClassesCenterLogin
  },
  {
    path: '/VbaseStar',
    name: 'baseStar',
    component: BaseStar,
    meta: {
      keepAlive: true, 
    },
  },
  {
    path: '/VworksDetail',
    name: 'worksDetail',
    component: WorksDetail
  },
  {
    path: '/Vabout_jiading',
    name: 'ABOUT',
    component: JiaDing
  },
  {
    path: '/Vabout_yuhang',
    name: 'ABOUT',
    component: YuHang
  },
  ...iframeRoutes,
  ...CerModul,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// const childRoute = ['/app-vue-hash','/app-vue-history','/app-vue-qing'];
// const isChildRoute = path => childRoute.some(item =>{ 
// 	 path.startsWith(item)
//  })
// const rawAppendChild = HTMLHeadElement.prototype.appendChild;
// const rawAddEventListener = window.addEventListener;
// //路由守卫
// router.beforeEach((to, from, next) => {
//   // 从子项目跳转到主项目
//   HTMLHeadElement.prototype.appendChild = rawAppendChild;
//   window.addEventListener = rawAddEventListener;
//   // if(isChildRoute(from.path) && !isChildRoute(to.path)){}
//   next();
  
// });

export default router
