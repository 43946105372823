/*
 * @Author: huanjian.chen harry.chen@ubtrobot.com
 * @Date: 2023-03-02 17:04:13
 * @LastEditors: huanjian.chen harry.chen@ubtrobot.com
 * @LastEditTime: 2023-03-06 13:37:36
 * @FilePath: \QingClassRoom_Pro\src\apis\Home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/http.js";

let url = 'https://ubg.qingsteam.cn/';

export function queryIndexList(options) {
  return request({
    url: url + "/businessPro/indexColumn",
    method: "post",
    data: options,
  });
}

export function queryBannerList(options) {
  return request({
    url: url + "/businessPro/queryBanner",
    method: "post",
    data: options,
  });
}

// 门户导航配置
export function getNavBar(options) {
  return request({
    url: url + "/businessPro/portalConfig",
    method: "post",
    data: options,
  });
}

// 首页分类
export function queryHomePageColumn(options) {
  return request({
    url: url + "/businessPro/homePageColumn",
    method: "post",
    data: options,
  });
}

// Ucode
export function queryUcodeUrl(options) {
  return request({
    url: url + "/getUBTToken",
    method: "post",
    data: options,
  });
}

// 产品配置
export function queryAllProduct(options) {
  return request({
    url: url + "/businessPro/queryAllProduct",
    method: "post",
    data: options,
  });
}


