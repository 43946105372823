var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-config-provider',{attrs:{"locale":_vm.locale}},[_c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"container"},[_c('header',{style:({ height: _vm.isHome ? '' : '72px' })},[_c('m-header',{attrs:{"navBarList":_vm.navConfig}})],1),_c('main',{attrs:{"id":"main"}},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)]),(
        _vm.atRouterName == 'offlineClasses' ||
        _vm.atRouterName == 'offlineClassesDetail' ||
        _vm.atRouterName == 'videoOpen' ||
        _vm.atRouterName == 'medal' ||
        _vm.atRouterName == 'medalDetails'||
        _vm.atRouterName == 'Subscription_Center' ||
        _vm.atRouterName == 'subscriptionDetail' ||
        _vm.atRouterName == 'subscriptionNumber'
          ? false
          : true
      )?_c('div',[_c('m-footer',{attrs:{"footConfig":_vm.navConfig}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }