import {getUserInfo} from '@/apis/user.js';
import store from '@/store/index.js';

export function queryUsers(){
  return new Promise((resolve,reject)=>{
    getUserInfo({}).then((res)=>{
        const msg=res.data;
        if(msg.status==0){
          if(!store.state.indexStore.B_PRO_USERS){
            store.commit('setUsers',msg.result);
          }
          resolve(msg);
        }
      }).catch(err=>{
        console.log(err);
      })
  })
}